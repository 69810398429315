import { Spin, Modal, Row } from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import Header from "../../components/Header";
import Order from "../../components/Oders";

import {
  ModalShipping,
  ModalCooking,
  ModalCookingPickup,
  ModalCancel,
  ModalPending,
  ModalReceived,
  ModalReadyToShip,
} from "../../components/Modal";

import "./index.scss";

import {
  renderButtonFunction,
  renderImageTitle,
  renderFooterReadyToShip,
  renderFooterShipping,
  renderStatus,
  renderSecondButton,
  renderModalData,
} from "./render";

import {
  apiCookingOrder,
  apiGetListOrder,
  apiReciveOrder,
  apiShipping,
  apiGetDetailOrder,
  apiBomOrder,
  apiMerchantReadyToShip,
  apiCompleteOrder,
  apiGetListOrderRefund,
  apiPushSocketIdToServer,
  apiGetListDining,
} from "../../apis/Order";

import { apiAssignShipper } from "../../apis/Shipper";
import { customNotification } from "../../utils/CommomFunction";
import DeliveredModal from "../../components/Modal/DeliveredModal";
import ModalWaringComplete from "../../components/Modal/ModalWaringComplete";
import ChangeDeliveryStaffModal from "../../components/Modal/ChangeDeliveryStaffModal";
import { RejectOrderModal } from "../../components/Modal/RejectOrderModal";
import moment from "moment";
import { STATUS_COLUMNS } from "./columns";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
// import { firebaseMessaging } from "../../firebaseInit";
import { OrderTransferModal } from "../../components/Modal/OrderTransferModal";
import sound from "../../assets/notification.mp3";
import soundNotResponse from "../../assets/ordernotresponse.wav";
import { apiGetNotificationList } from "../../apis/Notification";
import NotificationComponent from "components/Notification";
import { statusConvertData } from "../../utils/CommomFunction";
import _, { debounce, isEqual } from "lodash";
import { useIdleTimer } from "react-idle-timer";
import { isJsonValid } from "../../utils/Validation/index";
import { wait, addDecodeIdOrderList } from "../../utils/CommomFunction/index";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import { Button } from "components/atom/Button";
import ButtonAction from "components/Button/ButtonAction";
import { useHistory } from "react-router-dom";
import useOpenModal from "./useOpenModal";
import { playNotiSound } from "utils";
import { apiGetCompletedOrder } from "apis/Order/completedOrder";

const { info } = Modal;
const SocketURL =
  process.env.REACT_APP_SOCKETURL || "https://fnb-socket.test88.info";
const statusConvert = {
  Complete: "complete",
  Cooking: "cooking",
  "Ready to Ship": "ready_to_ship",
  Canceled: "canceled",
  Shipping: "shipping",
  Received: "received",
  Processing: "pending",
  Pending: "pending",
  Sent: "received",
  done: "serving",
  processing: "pending",
  pending: "pending",
  sent: "received",
  done: "serving",
  canceled: "canceled",
  cooking: "cooking",
  ready_to_ship: "ready_to_ship",
  shipping: "shipping",
  received: "received",
  ready: "ready_to_ship",
  complete: "complete",
};

function getRandomInt(min, max) {
  /**
   * Generate a random integer within the specified range [min, max].
   *
   * @param {number} min - Minimum value for the generated integer.
   * @param {number} max - Maximum value for the generated integer.
   * @returns {number} - A random integer within the specified range.
   */

  min = Math.ceil(min);
  max = Math.floor(max);

  // Math.random() generates a random number in the range [0, 1)
  // Multiplying by (max - min + 1) gives a range [0, max - min]
  // Adding min ensures the result is in the range [min, max]
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const HomePage = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [diningQuoteList, setDiningQuoteList] = useState([]);
  const [diningOrderList, setDiningOrderList] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shipperList, setShipperList] = useState();
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [detailOrder, setDetailOrder] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonTop, setLoadingButtonTop] = useState(false);
  const [updateShipper, setUpdateShipper] = useState(false);
  const [refundOrderList, setRefundOrderList] = useState([]);
  const currentModalOpen = useRef();
  const currentModalCancel = useRef();
  const currentModalOrder = useRef("");
  const loopPlayAudio = useRef();
  const loopPlayAudioNotResponse = useRef();
  const playAudio = useRef();
  const [reload, set_reload] = useState(false);

  const [isLoadingApp, setIsLoadingApp] = useState(false);

  const [turnOffAppSound, setTurnOffAppSound] = React.useState(2);
  const [orderListNotResponse, setOrderListNotResponse] = useState([]);

  const [dataOrderModal, setDataOrderModal] = useState();
  const [isShowModalPending, setShowModalPending] = useState(false);
  const [isShowModalRecieved, setShowModalRecieved] = useState(false);
  const [isShowModalReadyShip, setShowModalReadyShip] = useState(false);
  const [isShowModalShipping, setShowModalShipping] = useState(false);
  const [isShowModalCancel, setShowModalCancel] = useState(false);
  const [isShowModalCooking, setShowModalCooking] = useState(false);
  const [isShowModalRejectOrder, setShowModalRejectOrder] = useState(false);
  const [isShowModalCookingPickUp, setIsShowModalCookingPickUp] =
    useState(false);
  const [timeReloadOrder, setTimeReloadOrder] = useState(null);

  const [isRequestNoti, setRequestNoti] = useState(false);
  const [isWarningComplete, setWarningComplete] = useState(false);
  const [messageWarningComplete, setMessageWarningComplete] = useState(false);
  const [isPickup, setIsPickup] = useState(false);

  const refOrderList = React.useRef([]);
  const intervalTitle = React.useRef();
  const reloadOrderRef = React.useRef();

  const orderDetailId =
    dataOrderModal && dataOrderModal?.id ? dataOrderModal?.id : -1;
  const findOrderOffSound = orderListNotResponse.find(
    (obj) => obj?.id == orderDetailId
  );
  const isOffSound =
    findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;

  const bc = new BroadcastChannel("jollibeeBroadcast");
  const { t } = useTranslation();

  const onIdle = () => {
    getOrderList();

    idleTimer.reset();
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive: () => {},
    timeout: 1000 * 60 * 9.5,
  });

  const saveOrderListNotResponse = ({ order, type }) => {
    if (type == "add") {
      const index = orderListNotResponse.findIndex(
        (obj) => obj?.id == order?.id
      );
      if (index < 0) {
        const tempList = [...orderListNotResponse, order];
        setOrderListNotResponse(tempList);
      }
    } else if (type == "remove") {
      const tempList = orderListNotResponse.filter(
        (obj) => obj?.id !== order?.id
      );
      if (!isEqual(tempList, orderListNotResponse)) {
        setOrderListNotResponse(tempList);
      }
    }
  };

  const pauseOrderNotResponse = ({ order, timePause }) => {
    const indexOrder = orderListNotResponse.findIndex(
      (obj) => obj?.id == order?.id
    );
    if (indexOrder !== -1) {
      const tempList = [...orderListNotResponse];
      tempList[indexOrder] = {
        ...orderListNotResponse[indexOrder],
        isOffSound: true,
        timePause,
      };
      setOrderListNotResponse(tempList);
    }
  };

  const playOrderNOtResponseAgain = ({ order }) => {
    const indexOrder = orderListNotResponse.findIndex(
      (obj) => obj?.id == order?.id
    );
    if (indexOrder !== -1) {
      const tempList = [...orderListNotResponse];
      tempList[indexOrder] = {
        ...orderListNotResponse[indexOrder],
        isOffSound: false,
        timePause: null,
      };
      setOrderListNotResponse(tempList);
    }
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const handleOnOffSound = () => {
    pauseOrderNotResponse({
      order: dataOrderModal,
      timePause: Date.parse(moment.utc().local().format()),
    });
    if (isShowModalCooking && dataOrderModal?.status == "cooking") {
      closeModalCooking();
    }
    setTimeout(() => {
      closeAllModal();
    }, 120);
  };

  const closeAllModal = () => {
    setShowModalCancel(false);
    setShowModalCooking(false);
    setIsShowModalCookingPickUp(false);
    setShowModalPending(false);
    setShowModalReadyShip(false);
    setShowModalRecieved(false);
    setShowModalRejectOrder(false);
    setShowModalShipping(false);
    setShowModalTransfer(false);
  };

  const orginalTitle = document.title;

  function flashText() {
    const newTitle = t("newOrder");
    if (document.title === orginalTitle) {
      document.title = newTitle;
    } else {
      document.title = orginalTitle;
    }
  }
  const startIntervalTitle = () => {
    intervalTitle.current = setInterval(() => {
      flashText();
    }, 2000);
  };

  const stopIntervalTitle = () => {
    clearInterval(intervalTitle.current);
    intervalTitle.current = null;
    document.title = orginalTitle;
  };

  async function updateOrder(order) {
    if (
      order?.status == "canceled" &&
      order?.payment_method_code !== "cashondelivery"
    ) {
      wait(2000).then(() => {
        getOrderListRefund();
      });
    }

    if (order?.status == "canceled" || order?.status == "complete") {
      /* UPDATE REMOVE ORDER IN ORDER LIST UPDATE STATIUS == COMPLETE || CANCELED */
      let tempOrderList = await [...refOrderList.current];
      tempOrderList = tempOrderList?.filter?.(
        (obj) => obj?.id?.toString?.() !== order?.id?.toString?.()
      );
      setOrderList(tempOrderList);
      refOrderList.current = tempOrderList;
    } else {
      /* UPDATE STATUS ORDER of ORDERLIST */
      for (let i = 0; i < refOrderList.current?.length; i++) {
        if (refOrderList.current[i].id == order?.id) {
          let tempOrderList = await [...refOrderList.current];
          if (
            statusConvertData[order?.status] >=
            statusConvertData[tempOrderList[i].status]
          ) {
            tempOrderList[i].status = order?.status;
            refOrderList.current = tempOrderList;
            setOrderList(tempOrderList);
            return;
          }
        }
      }
    }
  }

  // useEffect(() => {
  //   firebaseMessaging.onMessage((payload) => {
  //     if (payload?.notification?.title?.toString()?.includes("Đơn hàng mới")) {
  //       const audio = new Audio(sound);
  //       audio.play();
  //       wait(5000).then(() => {
  //         getOrderList();
  //       });
  //     } else {
  //       if (payload?.data?.order) {
  //         let order = payload?.data?.order;
  //         if (isJsonValid(payload?.data?.order)) {
  //           order = JSON.parse(payload?.data?.order);
  //         }
  //         wait(2000).then(() => {
  //           updateOrder(order);
  //         });
  //       } else {
  //         wait(5000).then(() => {
  //           getOrderList();
  //         });
  //       }
  //     }

  //     customNotification(
  //       "success",
  //       <NotificationComponent
  //         title={payload?.notification?.title}
  //         content={payload?.notification?.body}
  //       />,
  //       "notification-custom"
  //     );

  //     apiGetNotificationList({ type: "merchant" }).then((res) => {
  //       if (currentModalOrder?.current?.status === "pending") {
  //         if (
  //           orderList.findIndex(
  //             (order) =>
  //               order.order_number === currentModalOrder.current.order_number
  //           ) === -1
  //         ) {
  //           currentModalCancel.current();
  //         }
  //       }
  //     });

  //     set_reload(!reload);
  //   });

  //   return () => {
  //     clearInterval(loopPlayAudio.current);
  //     clearTimeout(playAudio.current);
  //   };
  // }, []);

  const setReload = () => {
    getOrderList();
  };

  const playSoundNotResponse = () => {
    const pendingOders = orderList.filter(
      (item) => item.status === "pending"
    ).length;
    if (loopPlayAudioNotResponse.current) return;
    if (pendingOders == 0) {
      const audio = new Audio(soundNotResponse);
      audio.play();
      loopPlayAudioNotResponse.current = setInterval(() => {
        const audio = new Audio(soundNotResponse);
        audio.play();
      }, 2000);
    }
  };

  useEffect(() => {
    // apiGetShipperList().then((res) => {
    //   if (!res.errors && res.data) {
    //     setShipperList(res.data.getShipperList.list);
    //   }
    // });
  }, [updateShipper]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listCompletedOrder, setListCompletedOrder] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [totalComplete, setTotalComplete] = useState(0);
  const getListCompleteOrder = async ({ page }) => {
    setLoading2(true);
    apiGetCompletedOrder({
      currentPage: page,
    })
      .then((res) => {
        if (!res.errors && res.data) {
          const list = res.data.merchantOrderCompleteDashboard.orders;
          const concatList = _.uniqBy(
            [...listCompletedOrder, ...list],
            "order_number"
          );
          setListCompletedOrder(concatList);
          setTotalComplete(res.data.merchantOrderCompleteDashboard.total_count);
        }
      })
      .finally(() => {
        setLoading2(false);
      });
  };
  useEffect(() => {
    getListCompleteOrder({
      page: currentPage,
    });
  }, [currentPage]);
  const fetchDetailOrder = async (id) => {
    const res = await apiGetDetailOrder({ id });
    if (!res.errors && res?.data) {
      setDetailOrder(res?.data?.orderDetail);
    } else if (res?.errors?.length && res.errors[0].message) {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>{t("failed")}</span>,
        content: res?.errors[0]?.message,
      });
    }
    return res;
  };

  const openModal = async (type, order) => {
    let res;
    switch (type) {
      case "pending":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalPending();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalPending;
          currentModalCancel.current = closeModalPending;
          currentModalOrder.current = order;
        }
        break;

      case "received":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalRecieved();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalRecieved;
          currentModalCancel.current = closeModalRecieved;
          currentModalOrder.current = order;
        }

        break;
      case "cooking":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          if (order?.shipping_method === "Giao hàng tận nơi") {
            setSelectedShipper(null);
            setUpdateShipper(!updateShipper);
            openModalCooking();
            currentModalOpen.current = openModalCooking;
            currentModalCancel.current = closeModalCooking;
            currentModalOrder.current = order;
          } else {
            currentModalOpen.current = openModalCookingPickUp;
            currentModalCancel.current = closeModalCookingPickUp;
            currentModalOrder.current = order;
            setIsShowModalCookingPickUp(true);
          }
          setDataOrderModal(res?.data?.orderDetail);
        }

        setIsLoading(false);

        return res;
      case "ready_to_ship":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalReadyShip();
          setUpdateShipper(!updateShipper);
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalReadyShip;
          currentModalCancel.current = closeModalReadyShip;
          currentModalOrder.current = order;
        }
        break;
      case "shipping":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalShipping();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalShipping;
          currentModalCancel.current = closeModalShipping;
          currentModalOrder.current = order;
        }

        break;
      case "arrived":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalShipping();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalShipping;
          currentModalCancel.current = closeModalShipping;
          currentModalOrder.current = order;
        }
        break;
      case "bom_request":
        res = await fetchDetailOrder(order.id);
        if (!res.errors && res.data) {
          openModalCancel();
          setDataOrderModal(res?.data?.orderDetail);
          currentModalOpen.current = openModalCancel;
          currentModalCancel.current = closeModalCancel;
          currentModalOrder.current = order;
        }
        break;
      default:
        return;
    }
  };

  const showModalCancel = () => {
    // currentModalCancel && currentModalCancel?.current();
    setShowModalRejectOrder(true);
  };

  const handleSelectShipper = (item) => {
    if (item?.orders?.length >= 2) {
      return info({
        icon: <></>,
        content: <span>{t("shipper2order")}</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setSelectedShipper(item?.id);
  };

  const [socket, setSocket] = React.useState(null);

  const callPushSocketIdToServer = (socketId) => {
    apiPushSocketIdToServer({ socketId });
  };

  const pushNotificationLocal = (order, message = null) => {
    const content =
      order?.status == "processing"
        ? `${t("notifyNewOrder")} ${order?.order_number}`
        : `${t("orderUpdateStatus")} ${order?.status}`;
    customNotification(
      "success",
      <NotificationComponent
        title={"Notification"}
        content={message ? message : content}
      />,
      "notification-custom"
    );
    playNotiSound();
  };

  useEffect(() => {
    // Kết nối tới máy chủ
    const socketInstance = io(SocketURL);

    // Lưu đối tượng socket vào state
    setSocket(socketInstance);

    // Thực hiện các xử lý khác khi kết nối thành công
    socketInstance.on("connect", () => {
      const socketId = socketInstance.id; //
      callPushSocketIdToServer(socketId);

      console.log("Kết nối thành công đến máy chủ socket.");
    });

    socketInstance.on("reconnect", (messages) => {});

    socketInstance.on("chat message", (msg, callback) => {
      console.log(msg);
      getOrderListWithoutLoading();
      if (msg?.message) {
        pushNotificationLocal(null, msg?.message);
      } else {
        if (
          msg?.order?.status === "received" ||
          msg?.order?.status === "complete"
        ) {
          console.log("Dont Show Notification", msg?.order?.status);
        } else {
          pushNotificationLocal(msg?.order);
        }
      }
    });

    return () => {
      // Ngắt kết nối khi component unmount
      if (socketInstance) {
        socketInstance.disconnect();
        socketInstance.off("client list");
      }
    };
  }, []);
  const history = useHistory();
  const renderTitleModal = () => {
    return (
      <>
        <div className="title-left">
          <h3>
            {t("order")} #{dataOrderModal?.order_number}{" "}
            {renderStatus(dataOrderModal, t)}
          </h3>

          {dataOrderModal?.external_order_id && (
            <p style={{ fontWeight: "400", fontSize: 13 }}>
              {`Mã đơn ${
                dataOrderModal?.order_type == "CALL CENTER" ||
                dataOrderModal?.order_type == "STORE"
                  ? "Hoa Sao"
                  : "zalo"
              }: `}
              #
              {dataOrderModal?.order_type == "CALL CENTER" ||
              dataOrderModal?.order_type == "STORE"
                ? dataOrderModal?.external_order_id
                : dataOrderModal?.external_order_id
                    ?.toString()
                    ?.slice(
                      dataOrderModal?.external_order_id?.toString().length - 9
                    )}{" "}
            </p>
          )}
          <h5>
            <p>{`${t("receivedAt")} ${moment(dataOrderModal?.created_at).format(
              "hh:mm A"
            )}`}</p>
            {dataOrderModal?.assign_from?.name &&
              dataOrderModal?.status === "pending" && (
                <>
                  <span></span>
                  <b>{`${t("movedFrom")} ${
                    dataOrderModal?.assign_from?.name
                  }`}</b>
                </>
              )}
          </h5>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {renderButtonFunction(
            dataOrderModal,
            showModalCancel,
            handleOnOffSound,
            !isOffSound && findOrderOffSound,
            t
          )}
          {dataOrderModal?.status === "ready_to_ship" &&
            dataOrderModal.shipping_method === "Giao hàng tận nơi" && (
              <span
                style={{ marginLeft: "10px" }}
                key="btn2"
                onClick={openModalChangeStaff}
              >
                {renderSecondButton(dataOrderModal)}
              </span>
            )}
          <ButtonAction
            onClick={() => history.push(`/bill?orderId=${detailOrder.id}`)}
            title={t("viewBill")}
            color={"#00B77F"}
          />
        </div>
      </>
    );
  };

  // =============Pending=============//
  const openModalPending = () => {
    setShowModalPending(true);
  };
  const closeModalPending = () => {
    setShowModalPending(false);
  };
  // =============Recieved=============//
  const openModalRecieved = () => {
    setShowModalRecieved(true);
  };
  const closeModalRecieved = () => {
    setShowModalRecieved(false);
  };

  // =============Ready ship =============//
  const openModalReadyShip = () => {
    setShowModalReadyShip(true);
  };
  const closeModalReadyShip = () => {
    setShowModalReadyShip(false);
  };

  // =============Shipping=============//
  const openModalShipping = () => {
    setShowModalShipping(true);
  };
  const closeModalShipping = () => {
    setShowModalShipping(false);
  };
  // =============Cancel=============//
  const openModalCancel = () => {
    setShowModalCancel(true);
  };
  const closeModalCancel = () => {
    setShowModalCancel(false);
  };
  // =============Cooking=============//
  const openModalCooking = () => {
    setShowModalCooking(true);
  };
  const closeModalCooking = () => {
    if (dataOrderModal?.id) {
      changeOrderList(dataOrderModal?.id, "cooking");
    }
    setShowModalCooking(false);
    setIsLoadingApp(false);
  };

  const openModalCookingPickUp = () => {
    setIsShowModalCookingPickUp(true);
  };

  const closeModalCookingPickUp = () => {
    setIsShowModalCookingPickUp(false);
  };

  const handleSubmitRecievedOrder = async (id) => {
    setLoadingButton(true);
    const res = await apiReciveOrder({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalPending(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitCookingOrder = async (id) => {
    setLoadingButton(true);
    const res = await apiCookingOrder({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalRecieved(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitReadyToShip = () => {
    if (!selectedShipper) {
      return info({
        icon: <></>,
        content: <span>{t("pleaseSelectShipper")}</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setLoadingButton(true);
    apiAssignShipper({
      shipperId: selectedShipper,
      orderId: dataOrderModal.id,
    }).then((res) => {
      setLoadingButton(false);
      setIsLoadingApp(false);
      if (!res.errors && res.data) {
        setShowModalCooking(false);
      }
      // getOrderList();
    });
  };

  const handleSubmitShipping = async (id) => {
    setLoadingButton(true);
    const res = await apiShipping({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setShowModalReadyShip(false);
      setShowModalShipping(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitBom = (dataOrder = dataOrderModal) => {
    setLoadingButton(true);
    apiBomOrder({
      id: dataOrder.id,
    }).then((res) => {
      setLoadingButton(false);
      if (!res.errors && res.data) {
        customNotification(
          "success",
          `${t("order")} ${dataOrder?.order_number} ${t("confirmBom")}`
        );
        setShowModalReadyShip(false);
        setShowModalShipping(false);
        closeModalCancel();
        setReload(!reload);
      }
    });
  };

  const handleSubmitOrderReadyToPickUp = async (id) => {
    setLoadingButton(true);
    const res = await apiMerchantReadyToShip({ id });
    setLoadingButton(false);
    if (!res.errors && res.data) {
      setIsShowModalCookingPickUp(false);
      // setReload(!reload);
      return true;
    } else {
      info({
        icon: <></>,
        title: <span style={{ fontWeight: "bold" }}>Thất bại</span>,
        content: res.errors[0].message,
      });
      return false;
    }
  };

  const handleSubmitComplete = () => {
    setIsShowModalDelivered(true);
  };

  const [isShowModalDelivered, setIsShowModalDelivered] = useState(false);
  const openModalDelivered = () => {
    setIsShowModalDelivered(true);
  };

  const closeModalDelivered = () => {
    setIsShowModalDelivered(false);
  };

  const [isShowModalChangeStaff, setIsShowModalChangeStaff] = useState(false);
  const openModalChangeStaff = () => {
    setIsShowModalChangeStaff(true);
    currentModalCancel.current();
  };

  const closeModalChangeStaff = () => {
    // apiGetListOrder().then((res) => {
    //   if (!res.errors && res.data) {
    //     setOrderList(res.data.merchantOrders.orders);
    //     refOrderList.current = res.data.merchantOrders.orders;
    //   }
    // });
    setIsShowModalChangeStaff(false);
  };

  const timeToEnd = (dataOrderModal) => {
    if (dataOrderModal?.pickup_date && dataOrderModal?.pickup_time) {
      const time = `${moment(dataOrderModal?.pickup_date, [
        "YYYY-MM-DD  HH:mm:ss",
      ]).format("YYYY-MM-DD ")} ${dataOrderModal?.pickup_time}`;
      return time;
    }

    return moment(dataOrderModal?.created_at)
      .add(Number(dataOrderModal?.delivery_time), "m")
      .format("hh:mm A");
  };

  const handleSubmitCompletePickUp = (data) => {
    setLoadingButtonTop(true);
    apiCompleteOrder({
      id: data?.id,
    }).then((res) => {
      if (!res.errors && res.data) {
        setReload();
        customNotification(
          "success",
          `Your order ${data?.order_number} have been successful`
        );
        setTimeout(() => {
          setLoadingButtonTop(false);

          setShowModalReadyShip(false);
        }, 500);
      }
    });
  };

  const changeOrderList = (id, status) => {
    const newOrderList = orderList?.map((item) => {
      if (item?.id === id) {
        return { ...item, status };
      }
      return item;
    });
    setOrderList(newOrderList);
    refOrderList.current = newOrderList;
  };

  const updateOrderNextStatus = (orderId, nextStatus) => {
    setIsLoadingApp(true);
    const indexUpdate = orderList?.findIndex((or) => or?.id == orderId);

    if (indexUpdate == -1) return;

    let tempOrderList = [...orderList];
    tempOrderList[indexUpdate].status = nextStatus;
    setOrderList(tempOrderList);
    refOrderList.current = tempOrderList;
  };

  const getOrderListWithoutLoading = () => {
    if (
      !isShowModalCancel ||
      !isShowModalChangeStaff ||
      !isShowModalCooking ||
      !isShowModalCookingPickUp ||
      !isShowModalDelivered ||
      !isShowModalPending ||
      !isShowModalReadyShip ||
      !isShowModalRecieved ||
      !isShowModalRejectOrder ||
      !isShowModalShipping
    ) {
      apiGetListDining().then((res) => {
        if (!res.errors && res.data) {
          setDiningQuoteList(res?.data?.merchantOrderDashboard?.quotes);
          setDiningOrderList(res?.data?.merchantOrderDashboard?.orders);
        }
      });
    }
  };

  React.useEffect(() => {
    reloadOrderRef.current = setInterval(
      () => {
        getOrderListWithoutLoading();
        getListCompleteOrder({ page: 1 });
      },
      timeReloadOrder ? parseInt(timeReloadOrder) * 1000 : 30000
    );

    return () => {
      clearInterval(reloadOrderRef.current);
    };
  }, []);

  const getOrderList = async () => {
    setIsLoadingApp(true);
    await apiGetListDining().then((res) => {
      if (!res.errors && res.data) {
        setDiningQuoteList(res?.data?.merchantOrderDashboard?.quotes);
        setDiningOrderList(res?.data?.merchantOrderDashboard?.orders);
      }
    });
    setIsLoadingApp(false);
  };

  const getOrderListRefund = () => {
    apiGetListOrderRefund().then((res) => {
      if (!res.errors && res.data) {
        setRefundOrderList(res.data.merchantOrderHistory?.orders || []);
      }
    });
  };

  const handleDragEnd = async ({ source, destination }) => {
    const orderId = source?.index;

    if (!destination || !orderId) return;

    const nextStatus = destination?.droppableId;

    switch (source.droppableId) {
      case "pending":
        if (destination.droppableId === "received") {
          updateOrderNextStatus(orderId, nextStatus);
          const status = await handleSubmitRecievedOrder(source.index);
          setIsLoadingApp(false);
          // getOrderList();
        }
        break;

      case "received":
        if (destination.droppableId === "cooking") {
          updateOrderNextStatus(orderId, nextStatus);
          const status = await handleSubmitCookingOrder(source.index);
          setIsLoadingApp(false);
          // if (status) {
          //   getOrderList();
          // }
        }
        break;

      case "cooking":
        if (destination.droppableId === "ready_to_ship") {
          updateOrderNextStatus(orderId, nextStatus);
          const order = orderList?.find(
            (item) => item.id === Number(source.index)
          );
          if (order?.shipping_method === "Giao hàng tận nơi") {
            const responseOpenModal = await openModal("cooking", order);
            if (responseOpenModal?.errors) {
              return getOrderList();
            }
            return;
          }
          const status = await handleSubmitOrderReadyToPickUp(source.index);
          setIsLoadingApp(false);
          // getOrderList();
        }
        break;

      case "ready_to_ship":
        if (destination.droppableId === "shipping") {
          const order = orderList?.find(
            (item) => item?.id == btoa(source.index)
          );

          if (order?.shipping_method.includes("Pickup In Store")) {
            return;
          }
          updateOrderNextStatus(orderId, nextStatus);
          const status = await handleSubmitShipping(source.index);

          if (order?.shipping_method === "Giao hàng tận nơi") {
            // getOrderList();
          }
          setIsLoadingApp(false);
        }
        break;
      default:
        break;
    }
  };

  const finalDelivered = () => {
    closeModalDelivered();
    closeModalShipping();
    updateOrder({ ...dataOrderModal, status: "complete" });
  };

  const [showModalTransfer, setShowModalTransfer] = useState(false);

  useEffect(() => {
    bc.onmessage = (eventMessage) => {
      setTimeout(() => {
        getOrderList();
      }, 2000);
    };
  }, []);

  useEffect(() => {
    const visibilityChange = () => {
      if (document.hidden) {
      } else {
        stopIntervalTitle();
        getOrderList();
      }
    };

    document.addEventListener("visibilitychange", visibilityChange);
  }, []);

  const renderHeaderColumnByStatus = (item, countOrderByStatus) => {
    return (
      <div className="columns-header">
        <div className="columns-header-left">
          <span className="columns-header-title">{t(item?.title)}</span>
        </div>

        <div className="colums-header-right">
          <span
            className="columns-header-count"
            style={{
              fontFamily: "Montserrat",
              fontSize: 32,
              fontWeight: "bold",
              color: "var(--secondary-1-light)",
            }}
          >
            {`${countOrderByStatus}`}
          </span>
          {/* {item?.status === "received" && (
                                <>
                                  <button className="btn-print-order">
                                    In hoá đơn
                                  </button>
                                </>
                              )} */}
        </div>
      </div>
    );
  };

  let ordersMapped = addDecodeIdOrderList(orderList);
  const {
    ModalDetail,
    orderDetails,
    handleOpen,
    open,
    loading,
    setOpen,
    headerData,
    invoiceData,
    setModalPrintBill,
    PrintBill,
    modalPrintBill,
  } = useOpenModal();
  useEffect(() => {
    if (diningQuoteList || diningOrderList || orderList) {
      const listQuote = diningQuoteList.map(
        (obj) =>
          obj && {
            ...obj,
            type: "dining-quotes",
            uniqueId: getRandomInt(1, 100000) + obj?.cart_id,
            status: statusConvert[obj?.serving_status],
            id: obj?.cart_id,
          }
      );
      try {
        const tableGroups = {};

        listQuote.forEach((order) => {
          const tableName = order?.table || "1";
          if (!tableGroups[tableName]) {
            tableGroups[tableName] = [];
          }
          tableGroups[tableName].push(order);
        });

        Object.keys(tableGroups).length > 0 &&
          Object.keys(tableGroups)?.forEach((tableName) => {
            const orders = tableGroups[tableName];
            if (orders.length > 1) {
              orders.forEach((order, index) => {
                if (order) {
                  order.table = `${tableName}-${index + 1}`;
                }
              });
            }
          });
      } catch (error) {
        console.log(error);
      }

      const listOrders = diningOrderList.map((obj) => ({
        ...obj,
        type: "dining-orders",
        uniqueId: getRandomInt(1, 100000) + parseInt(obj?.order_number),
        status:
          obj?.order_source === "DINING"
            ? statusConvert[obj?.serving_status]
            : obj?.order_source === "PICKUP" && obj?.table_id
            ? statusConvert[obj?.serving_status]
            : statusConvert[obj?.status],
      }));

      const newList = [...listQuote, ...listOrders].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      setRenderList(newList);
    }
  }, [diningQuoteList, diningOrderList, orderList]);
  const [search, setSearchValue] = useState("");
  const scrollRef = useRef(null);
  const handleScroll = useCallback(
    debounce(() => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          if (scrollTop > 10) {
            if (loading2) return;
            setCurrentPage((prev) => prev + 1);
            scrollRef.current.scrollTo({
              top: scrollTop - 200, // Scroll lên 50px
              behavior: "smooth", // Hiệu ứng cuộn mượt mà
            });
          }
        }
      }
    }, 300),
    [currentPage, loading2]
  );
  useEffect(() => {
    const node = scrollRef.current;
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (node) {
        node.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentPage, loading2]);
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="home-page" style={{ position: "relative" }}>
        {isLoadingApp && (
          <div className="loading_container">
            <Spin />
          </div>
        )}
        <Header
          reload={reload}
          refundOrderList={refundOrderList}
          setSearchValue={setSearchValue}
        />

        {renderList && !isLoading ? (
          <div className="home-board">
            <div className="container-box">
              <div className="board-wrapper">
                {STATUS_COLUMNS?.map((item, index) => {
                  const list_order = renderList
                    .filter(
                      (order) =>
                        order?.order_number?.includes?.(search) ||
                        order?.table
                          ?.toLowerCase()
                          ?.includes?.(search?.toLowerCase()) ||
                        order?.first_name
                          ?.toLowerCase()
                          ?.includes?.(search?.toLowerCase()) ||
                        order?.phone_number?.includes?.(search)
                    )
                    ?.map((order_item) => {
                      if (
                        order_item?.status?.toLowerCase?.() ===
                          item?.status?.toLowerCase?.() ||
                        item?.status
                          ?.toLowerCase()
                          ?.includes?.(order_item?.status?.toLowerCase())
                      ) {
                        return order_item;
                      }
                      if (
                        order_item?.status?.toLowerCase?.() === "bom_request" &&
                        item?.status === "shipping"
                      ) {
                        return order_item;
                      }
                      if (
                        order_item?.status?.toLowerCase?.() === "arrived" &&
                        item?.status?.toLowerCase?.() === "shipping"
                      ) {
                        return order_item;
                      }
                      return null;
                    })
                    .filter((item) => item);
                  const countOrderByStatus = list_order?.length ?? 0;

                  return (
                    <Droppable droppableId={item?.status} key={index}>
                      {(provided, _snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="board-columns"
                          key={index}
                        >
                          {/******************** RENER TITLE HEADER STATUS *********************/}

                          {renderHeaderColumnByStatus(item, countOrderByStatus)}

                          {/******************** RENER LIST ORDER BY COLUMN STATUS *********************/}
                          <div className="colums-wrapper">
                            {list_order?.map((order, i) => {
                              return (
                                <Order
                                  key={order?.order_number}
                                  handleSubmitBom={handleSubmitBom}
                                  openModal={(status, order) =>
                                    handleOpen(status, order)
                                  }
                                  order={order}
                                  id={i}
                                  playSound={playSoundNotResponse}
                                  saveOrderListNotResponse={
                                    saveOrderListNotResponse
                                  }
                                  orderListNotResponse={orderListNotResponse}
                                  playOrderNOtResponseAgain={
                                    playOrderNOtResponseAgain
                                  }
                                  turnOffAppSound={turnOffAppSound}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  );
                })}

                <div
                  ref={scrollRef}
                  className="scrollable board-columns"
                  key={"completed-6"}
                  style={{ overflowY: "auto" }}
                >
                  {/******************** RENER TITLE HEADER STATUS *********************/}

                  {renderHeaderColumnByStatus(
                    {
                      title: "COMPLETED",
                      status: "completed",
                    },
                    totalComplete
                  )}

                  {/******************** RENER LIST ORDER BY COLUMN STATUS *********************/}
                  <div className="colums-wrapper">
                    {listCompletedOrder
                      ?.filter(
                        (order) =>
                          order?.order_number?.includes?.(search) ||
                          order?.table
                            ?.toLowerCase()
                            ?.includes?.(search?.toLowerCase()) ||
                          order?.first_name
                            ?.toLowerCase()
                            ?.includes?.(search?.toLowerCase()) ||
                          order?.phone_number?.includes?.(search)
                      )
                      ?.map((order, i) => {
                        return (
                          <Order
                            key={order?.order_number}
                            handleSubmitBom={handleSubmitBom}
                            openModal={(status, order) =>
                              handleOpen(status, order)
                            }
                            order={order}
                            id={i}
                            playSound={playSoundNotResponse}
                            saveOrderListNotResponse={saveOrderListNotResponse}
                            orderListNotResponse={orderListNotResponse}
                            playOrderNOtResponseAgain={
                              playOrderNOtResponseAgain
                            }
                            turnOffAppSound={turnOffAppSound}
                            isCompletedOrder={true}
                          />
                        );
                      })}
                  </div>
                  {loading2 && <Spin />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Row justify="center" style={{ marginTop: 200 }}>
            <Spin spinning />
          </Row>
        )}
        {
          <ModalDetail
            open={open}
            loading={loading}
            data={orderDetails}
            onClose={() => setOpen(false)}
            headerData={headerData}
            handleSubmitRecievedOrder={async (orderId) => {
              await handleSubmitRecievedOrder(orderId);
              await updateOrderNextStatus(orderId, "received");
              setIsLoadingApp(false);
              setOpen(false);
            }}
            handleCancel={(data) => {
              setDataOrderModal(data);
              showModalCancel();
            }}
            handleSubmitCompletePickUp={(data) => {
              handleSubmitCompletePickUp(data);
              setOpen(false);
            }}
            invoiceData={invoiceData}
            setModalPrintBill={setModalPrintBill}
            PrintBill={PrintBill}
            modalPrintBill={modalPrintBill}
          />
        }
        {/* =====================Modal PENDING==================== */}
        <ModalPending
          isShowModalPending={isShowModalPending}
          closeModalPending={closeModalPending}
          handleSubmitRecievedOrder={async (orderId) => {
            await handleSubmitRecievedOrder(orderId);
            await updateOrderNextStatus(orderId, "received");
            setIsLoadingApp(false);
          }}
          dataOrderModal={dataOrderModal}
          loadingButton={loadingButton}
          currentModalCancel={currentModalCancel}
          currentModalOpen={currentModalOpen}
          setReload={setReload}
          reload={reload}
          handleClick={showModalCancel}
          setShowModalPending={setShowModalPending}
          setShowModalTransfer={setShowModalTransfer}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          renderTitleModal={renderTitleModal}
        />

        <OrderTransferModal
          reload={reload}
          key="transfer"
          dataOrder={dataOrderModal}
          showModalTransfer={showModalTransfer}
          closeModalTransfer={() => {
            setShowModalTransfer(false);
          }}
          openCurrentModalOpen={() => {
            currentModalOpen.current();
          }}
          handleClose={() => {
            setShowModalPending(false);
            setReload(!reload);
          }}
        />
        {/* ===================Modal RECEIVED==================== */}

        <ModalReceived
          renderTitleModal={renderTitleModal}
          isShowModalRecieved={isShowModalRecieved}
          closeModalRecieved={closeModalRecieved}
          dataOrderModal={dataOrderModal}
          handleSubmitCookingOrder={async (orderId) => {
            handleSubmitCookingOrder(orderId);
            await updateOrderNextStatus(orderId, "cooking");
            setIsLoadingApp(false);
          }}
          loadingButton={loadingButton}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          t={t}
        />

        {/* ===================Modal READY TO SHIP==================== */}
        <ModalReadyToShip
          renderTitleModal={renderTitleModal}
          isShowModalReadyShip={isShowModalReadyShip}
          dy
          closeModalReadyShip={closeModalReadyShip}
          footer={renderFooterReadyToShip(
            dataOrderModal,
            loadingButton,
            loadingButtonTop,
            handleSubmitBom,
            async (orderId) => {
              handleSubmitShipping(orderId);
              await updateOrderNextStatus(orderId, "shipping");
              setIsLoadingApp(false);
            },
            handleSubmitCompletePickUp
          )}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
        />

        {/* ===================Modal SHIPPING==================== */}
        <ModalShipping
          renderTitleModal={renderTitleModal}
          isShowModalShipping={isShowModalShipping}
          closeModalShipping={closeModalShipping}
          footer={renderFooterShipping(
            dataOrderModal,
            loadingButton,
            handleSubmitBom,
            handleSubmitComplete
          )}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
          openModalDelivered={openModalDelivered}
        />

        {/* ===================Modal CANCLE==================== */}
        <ModalCancel
          renderTitleModal={renderTitleModal}
          isShowModalCancel={isShowModalCancel}
          closeModalCancel={closeModalCancel}
          dataOrderModal={dataOrderModal}
          loadingButton={loadingButton}
          handleSubmitBom={handleSubmitBom}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
        />

        {/* ===================Modal COOKING PICK UP==================== */}
        <ModalCookingPickup
          renderTitleModal={renderTitleModal()}
          isShowModalCookingPickUp={isShowModalCookingPickUp}
          setIsShowModalCookingPickUp={setIsShowModalCookingPickUp}
          handleSubmitOrderReadyToPickUp={handleSubmitOrderReadyToPickUp}
          loadingButton={loadingButton}
          renderModalData={renderModalData}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
        />

        {/* ===================Modal COOKING==================== */}
        <ModalCooking
          title={renderTitleModal()}
          isShowModalCooking={isShowModalCooking}
          closeModalCooking={closeModalCooking}
          detailOrder={detailOrder}
          dataOrderModal={dataOrderModal}
          timeToEnd={timeToEnd}
          shipperList={shipperList}
          handleSelectShipper={handleSelectShipper}
          selectedShipper={selectedShipper}
          handleSubmitReadyToShip={async () => {
            await handleSubmitReadyToShip();
            await updateOrderNextStatus(dataOrderModal?.id, "ready_to_ship");
            setIsLoadingApp(false);
          }}
          loadingButton={loadingButton}
        />

        {/* ==================Modal DeliveredModal================ */}
        <DeliveredModal
          isShowModalDelivered={isShowModalDelivered}
          closeModalDelivered={closeModalDelivered}
          dataOrder={dataOrderModal}
          finalDelivered={finalDelivered}
          onOpenWarningComplete={(message) => {
            setMessageWarningComplete(message);
            setWarningComplete(true);
          }}
        />

        {/* ==================Modal ChangeDeliveryStaff================ */}
        <ChangeDeliveryStaffModal
          closeModalReadyShip={() => setShowModalReadyShip(false)}
          openCurrentModal={currentModalOpen.current}
          reload={() => setReload(!reload)}
          dataOrder={dataOrderModal}
          closeModalChangeStaff={closeModalChangeStaff}
          isShowModalChangeStaff={isShowModalChangeStaff}
        />

        {/* ==================Modal Reject Order================ */}
        <RejectOrderModal
          reload={() => setReload(!reload)}
          dataOrder={dataOrderModal}
          isShowModalRejectOrder={isShowModalRejectOrder}
          closeModalRejectOrder={() => setShowModalRejectOrder(false)}
          returnModal={() => currentModalOpen.current()}
          submitRejectOrder={() => {
            setShowModalRejectOrder(false);
            updateOrder({ ...dataOrderModal, status: "canceled" });
            setOpen(!open);
            // setReload(!reload);
          }}
        />

        <ModalWaringComplete
          isShowModal={isWarningComplete}
          closeModal={() => {
            setWarningComplete(false);
            setIsPickup(false);
          }}
          messageWarningComplete={messageWarningComplete}
          isPickup={isPickup}
        />
      </div>
    </DragDropContext>
  );
};

export default HomePage;
