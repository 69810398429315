import { Input, Modal, Row } from "antd";
import CloseXIcon from "assets/icon/closeIcon";
import { Text } from "components/atom/Text";
import ButtonAction from "components/Button/ButtonAction";
import React, { useEffect, useRef, useState } from "react";
export default function ModalInput({
  isModalOpen,
  onCancel,
  onSubmit,
  title,
  type,
}) {
  const inputRef = useRef(null);
  const [value, setValue] = React.useState("");
  useEffect(() => {
    // Kiểm tra xem modal có mở không
    if (isModalOpen && inputRef.current) {
      // Nếu có, thì focus vào input
      inputRef.current.focus();
    } else {
      setValue("");
    }
  }, [isModalOpen]);
  const onFinish = () => {
    if (validateInput(value)) {
      onSubmit(value);
    }
  };
  const [error, setError] = useState();
  const validateInput = (value) => {
    if (type === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setError("Invalid email address");
        return false;
      }
    } else if (type === "tel") {
      const phoneRegex = /^\d+$/; // Chỉ kiểm tra số điện thoại cơ bản, bạn có thể tùy chỉnh theo yêu cầu
      if (!phoneRegex.test(value)) {
        setError("Invalid phone number");
        return false;
      }
    }
    setError(null);
    return true;
  };
  return (
    <Modal
      open={isModalOpen}
      focusTriggerAfterClose
      closeIcon={<></>}
      closable={false}
      centered
      footer={null}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Text style={{ fontWeight: 600, color: "var(--text-primary)" }}>
          {title}
        </Text>
        <div style={{ cursor: "pointer" }} onClick={onCancel}>
          <CloseXIcon />
        </div>
      </Row>

      <Row align={"middle"} style={{ gap: 10, marginTop: 20 }}>
        <Input
          ref={inputRef}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          style={{
            flex: 1,
            height: 56,
            backgroundColor: "var(--neutral-base)", //"theme.nEUTRALBase,
            color: "var(--text-primary)", // theme.tEXTPrimary,
            border: `1px solid var(--neutral-line)`,
          }}
          inputMode={type}
        />
        <ButtonAction title="Send" onClick={onFinish} />
      </Row>
      {error && <Text style={{ color: "red", marginTop: 10 }}>{error}</Text>}
    </Modal>
  );
}
