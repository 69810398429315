import { Col, Modal, notification, Row, Spin } from "antd";
import { apiGetDetailOrder, apiGetDetailQuote } from "apis/Order";
import React, { useState, useCallback } from "react";
import { colorsData } from "./constant";
import { capitalizeFirstLetter } from "utils/renderText";
import PickUpIcon from "assets/pickUpIcon";
import ShippingIcon from "assets/shippingIcon";
import AccountIcon from "assets/accountIcon";
import Tag from "components/atom/Tag";
import RenderItem from "./NewModalComponent/RenderItem";
import ButtonReceipt from "./NewModalComponent/ButtonReceipt";
import { useHistory } from "react-router-dom";
import ButtonAction from "./NewModalComponent/ButtonAction";
import { useTranslation } from "react-i18next";
import DineInIcon from "assets/dineInIcon";
import RenderItemDining from "./NewModalComponent/RenderItemDining";
import RenderTotal from "./NewModalComponent/RenderTotal";
import RenderTotalDining from "./NewModalComponent/RenderTotalDining";
import moment from "moment";
import { apiGetInvoice } from "apis/Invoice";
import { ModalSelectBillToPrint } from "layouts/components/ModalSelectBillToPrint";
import { apiPrintInvoice } from "apis/Printer";
const { info } = Modal;
const urlWaiter = process.env.REACT_APP_WAITER_URL;
// Component để hiển thị Modal

/**
 * @typedef {Object} OrderDetail
 * @property {number} id
 * @property {string} order_number
 * @property {string} created_at
 * @property {string} updated_at
 * @property {number} grand_total
 * @property {string} status
 * @property {string|null} flag_refund
 * @property {string} payment_method
 * @property {string} payment_method_code
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} phone
 * @property {string} address
 * @property {string|null} customer_comment
 * @property {Array<{name: string, qty: number, price: number, status: string|null, note: string|null, options: Array<any>}>} items
 * @property {Array<any>} discount
 * @property {string} shipping_method
 * @property {string|null} use_plastic
 * @property {string|null} note
 * @property {string} customer_phone
 * @property {string|null} assign_from
 * @property {string|null} assign_reason
 * @property {number} shipping_amount
 * @property {string} table_id
 * @property {Object} total
 * @property {Object} total.tip_amount
 * @property {number} total.tip_amount.value
 * @property {Object} total.subtotal
 * @property {number} total.subtotal.value
 * @property {Object} total.total_shipping
 * @property {number} total.total_shipping.value
 * @property {Object} total.total_tax
 * @property {number} total.total_tax.value
 * @property {Object} total.grand_total
 * @property {number} total.grand_total.value
 */

export default function useOpenModal() {
  const [open, setOpen] = useState(false);
  /** @type {[OrderDetail|null, React.Dispatch<React.SetStateAction<OrderDetail|null>>]} */
  const [orderDetails, setOrderDetails] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(false); // Khởi tạo loading với false
  const [headerData, setHeaderData] = useState(null);
  const { t } = useTranslation();
  const handleOpen = useCallback(async (status, order) => {
    setHeaderData({ ...order, status });
    setOpen(true);
    setLoading(true);
    if (order?.order_number) {
      const res = await apiGetDetailOrder({ id: order?.id });
      setLoading(false);
      console.log(status);
      if (!res.errors && res.data) {
        setOrderDetails(res?.data?.orderDetail);
      } else {
        info({
          icon: <></>,
          title: <span style={{ fontWeight: "bold" }}>{t("failed")}</span>,
          content: res?.errors[0]?.message,
        });
      }
      const getInvoice = await apiGetInvoice({
        OrderNumber: order?.order_number,
      });
      if (!getInvoice.errors && getInvoice.data) {
        console.log(getInvoice?.data?.merchantGetOrderInvoices?.invoice);
        setInvoiceData(getInvoice?.data?.merchantGetOrderInvoices?.invoice);
      }
    } else {
      const res = await apiGetDetailQuote({ cart_id: order?.cart_id });
      setLoading(false);
      if (!res.errors && res.data) {
        console.log(res?.data);
        setOrderDetails({ ...res?.data?.merchantCart, type: "quote" });
      } else {
        info({
          icon: <></>,
          title: <span style={{ fontWeight: "bold" }}>{t("failed")}</span>,
          content: res?.errors[0]?.message,
        });
      }
    }
  }, []);
  const [modalPrintBill, setModalPrintBill] = useState(false);
  const PrintBill = () => {
    if (invoiceData.length > 1) {
      setModalPrintBill(true);
    } else {
      apiPrintInvoice({ invoice_number: invoiceData[0]?.number })
        .then(
          notification.success({
            message: "Send request print bill success",
            description: "Please go to printer to take the bill!",
          })
        )
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err?.errors?.[0]?.message,
          });
        });
    }
  };
  return {
    ModalDetail,
    handleOpen,
    open,
    orderDetails,
    loading,
    setOpen,
    headerData,
    modalPrintBill,
    invoiceData,
    setModalPrintBill,
    PrintBill,
  };
}
/**
 * @type {React.FC<{open: boolean, onClose: () => void, loading: boolean, data: OrderDetail | null}>}
 */
const ModalDetail = React.memo(
  ({
    open,
    onClose,
    loading,
    data,
    headerData,
    handleSubmitRecievedOrder,
    handleCancel,
    handleSubmitCompletePickUp,
    invoiceData,
    setModalPrintBill,
    PrintBill,
    modalPrintBill,
  }) => {
    const history = useHistory();
    return (
      <>
        <Modal
          key="received"
          centered={true}
          destroyOnClose={true}
          className="modal-v2"
          visible={open}
          width={632}
          onCancel={onClose}
          closable={false}
          footer={null}
        >
          {!loading ? (
            <>
              <div
                style={{
                  display: "flex",
                  height: 44,
                  border: `1px solid var(${
                    colorsData[headerData?.status]?.borderColor ||
                    "--tertiary-2-default"
                  })`,
                  background: `var(${
                    colorsData[headerData?.status]?.background ||
                    "--tertiary-1---bg"
                  })`,
                  borderRadius: 4,
                  padding: "10px 16px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={{
                      color: `var(${
                        colorsData[headerData?.status]?.borderColor ||
                        "--tertiary-2-default"
                      })`,
                      fontWeight: 600,
                    }}
                  >
                    {capitalizeFirstLetter(
                      headerData?.status === "pending"
                        ? "New"
                        : headerData?.status === "ready_to_ship"
                        ? "Ready"
                        : headerData?.status || "New"
                    )}
                  </span>
                  {headerData?.order_source?.includes("DELIVERY") ? (
                    <>
                      <ShippingIcon />{" "}
                      <span style={{ fontWeight: 600 }}>Delivery </span>
                    </>
                  ) : headerData?.order_source === "DINING" ? (
                    <>
                      <DineInIcon />{" "}
                      <span style={{ fontWeight: 600 }}>Dine In </span>
                    </>
                  ) : headerData?.order_source === "PICKUP" ? (
                    <>
                      <PickUpIcon />
                      <span style={{ fontWeight: 600 }}>Pick Up </span>
                    </>
                  ) : (
                    <>
                      <DineInIcon />{" "}
                      <span style={{ fontWeight: 600 }}>Dine In </span>
                    </>
                  )}
                  {headerData?.order_number || headerData?.table}{" "}
                  {headerData?.table &&
                    headerData?.order_number &&
                    `- ${headerData?.table}`}
                </div>
                <div>
                  <span>
                    Ordered at{" "}
                    {moment
                      .utc(headerData?.created_at)
                      .local()
                      .format("YYYY-MM-DD  HH:mm")}
                  </span>
                </div>
              </div>
              {/* Customer Infomation  */}

              <div style={{ padding: 16 }}>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <AccountIcon />
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {data?.firstname} {data?.lastname} {data?.customer_phone}
                  </span>
                </div>
                {headerData?.order_source !== "DINING" && (
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 16,
                      color: "var(--text-secondary)",
                    }}
                  >
                    {data?.address}
                  </div>
                )}
                {data?.waiter_name && (
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 16,
                      color: "var(--text-secondary)",
                    }}
                  >
                    Waiter: {data?.waiter_name}
                  </div>
                )}
              </div>
              {/* Order Items */}
              <div
                style={{
                  height: 1,
                  border: "1px solid var(--neutral-line)",
                  marginBottom: 16,
                }}
              />
              <div style={{ maxHeight: 200, overflow: "auto" }}>
                {data?.items?.map((item) => {
                  return item?.id ? (
                    <RenderItemDining item={item} key={item?.id} />
                  ) : (
                    <RenderItem item={item} key={item?.id} />
                  );
                })}
              </div>
              <div
                style={{
                  height: 1,
                  border: "1px solid var(--neutral-line)",
                  marginBottom: 16,
                }}
              />
              {/* Render Payment  */}
              <Row style={{ marginBottom: 24 }}>
                <Col
                  span={12}
                  style={{ borderRight: "1px solid var(--neutral-line)" }}
                >
                  {headerData?.type === "dining-quotes" ? (
                    <Row align={"middle"} style={{ gap: 16 }}>
                      <div style={{ fontSize: 16, fontWeight: 600 }}>
                        Payment{" "}
                      </div>
                      <Tag type="canceled" title="Unpaid" />
                    </Row>
                  ) : (
                    <div>
                      <Row>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            marginRight: 20,
                          }}
                        >
                          Payment{" "}
                        </div>
                        {data?.payment_method_code?.toLowerCase() ===
                        "arise" ? (
                          <Tag type="paid" title="Paid" />
                        ) : data?.order_number && data?.table_id ? (
                          <Tag type="paid" title="Paid" />
                        ) : (
                          <Tag type="canceled" title="Unpaid" />
                        )}
                      </Row>
                      <div style={{ marginTop: 12 }}>
                        Method {data?.payment_method}
                      </div>
                      {/* <div>Invoice Id</div> */}
                      <div
                        style={{ fontSize: 16, fontWeight: 600, marginTop: 12 }}
                      >
                        Receipt
                      </div>

                      <Row style={{ gap: 10, marginTop: 12 }}>
                        <ButtonReceipt
                          title={"View"}
                          borderColor={"--primary-6"}
                          textColor={"--primary-6"}
                          onPress={() =>
                            history.push(`/bill?orderId=${data.id}`)
                          }
                        />
                        <ButtonReceipt
                          title={"Print"}
                          background={"--primary-6"}
                          textColor={"--neutral-primary"}
                          onPress={() => {
                            PrintBill();
                          }}
                        />
                      </Row>
                    </div>
                  )}
                </Col>
                <Col span={12} style={{ paddingLeft: 16 }}>
                  {headerData?.type === "dining-quotes" ? (
                    <RenderTotalDining data={data} />
                  ) : (
                    <RenderTotal data={data} />
                  )}
                </Col>
              </Row>

              {/* Render Button Action */}

              {headerData?.table ? (
                <ButtonAction
                  title={"Go to table"}
                  background={"--primary-6"}
                  textColor={"--primary-1"}
                  onPress={() => {
                    window.location.href = `${urlWaiter}table?tableId=${
                      headerData?.table_id
                    }&token=${localStorage.getItem(
                      "access_token"
                    )}&from=merchant`;
                  }}
                />
              ) : headerData?.status === "pending" ? (
                <Row style={{ gap: 32 }} justify={"center"}>
                  <ButtonAction
                    title={"Cancel"}
                    background={"--error-1-bg"}
                    textColor={"--error-2-default"}
                    width="45%"
                    onPress={() => {
                      handleCancel(data);
                    }}
                  />
                  <ButtonAction
                    title={"Receive"}
                    background={"--info-2-default"}
                    textColor={"--primary-1"}
                    width="45%"
                    onPress={() => {
                      handleSubmitRecievedOrder(data?.id);
                    }}
                  />
                </Row>
              ) : headerData?.status === "ready_to_ship" ? (
                <Row style={{ gap: 32 }} justify={"center"}>
                  <ButtonAction
                    title={"Cancel"}
                    background={"--error-1-bg"}
                    textColor={"--error-2-default"}
                    width="45%"
                    onPress={() => {
                      handleCancel(data);
                    }}
                  />
                  <ButtonAction
                    title={"Complete"}
                    background={"--primary-6"}
                    textColor={"--primary-1"}
                    width="45%"
                    onPress={() => {
                      handleSubmitCompletePickUp(data);
                    }}
                  />
                </Row>
              ) : (
                <ButtonAction
                  title={"Cancel"}
                  background={"--error-1-bg"}
                  textColor={"--error-2-default"}
                  onPress={() => {
                    handleCancel(data);
                  }}
                />
              )}
            </>
          ) : (
            <div
              style={{
                height: "460px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Spin />
            </div>
          )}
        </Modal>
        {modalPrintBill && (
          <ModalSelectBillToPrint
            data={invoiceData}
            isVisibleModal={modalPrintBill}
            setVisibleMoal={setModalPrintBill}
            onPressOK={PrintBill}
          />
        )}
      </>
    );
  }
);
