import { callApi } from "..";
export const apiGetPrinterDeviceList = () =>
  callApi(
    `{
  merchantGetListDevice {
    restaurant_id,
    prints {
      id
      printer_name
    }
  }
}`
  );

export const apiSetPrinterDevice = (data) =>
  callApi(
    `mutation($printer_id:Int!) {
    merchantConfigPrinter (printer_id: $printer_id)
}`,
    data
  );

export const apiPrintInvoice = (data) =>
  callApi(
    `mutation ($invoice_number: String!) {
    merchantPrintReceiptCustomer (invoice_number: $invoice_number)
}`,
    data
  );

export const apiSendToEmail = (data) =>
  callApi(
    `
 mutation ($invoiceNumber: String!, $email: String!) {
        merchantSendReceiptToEmail(
            input: { invoice_number: $invoiceNumber, email: $email }
        )
    }
`,
    data
  );

export const apiSendBillToPhone = (data) =>
  callApi(
    ` mutation ($invoiceNumber: String!, $phoneNumber: String!) {
        merchantSendReceiptToSms(
            input: {
                invoice_number: $invoiceNumber
                phone_number: $phoneNumber
                region_code: "+1"
            }
        )
    }`,
    data
  );

export const apiPrintBill = (data) =>
  callApi(
    `
    mutation ($invoice_number: String!) {
        merchantPrintReceiptCustomer(invoice_number: $invoice_number)
    }
`,
    data
  );
export const apiOpenCashier = (data) =>
  callApi(
    `mutation { 
    merchantOpenCashier
}`,
    data
  );
