import moment from "moment";
import { callApi } from "..";
export const apiGetMerchantInfo = () =>
  callApi(
    `{
      getMerchantInfo {
        lastname
        firstname
        restaurant_address
        restaurant_name
        restaurant_id
        is_dine_in
        store_view_code
      }
    }`
  );
export const apiGetListOrder = () => {
  const currentDate = moment();
  const date_create_from = currentDate
    .subtract(1, "day")
    .startOf("day")
    .format("YYYY-MM-DD  HH:mm:ss");
  const date_create_to = currentDate
    .add(2, "day")
    .endOf("day")
    .format("YYYY-MM-DD  HH:mm:ss");

  return callApi(
    `{
      merchantOrders(
        sort: {
          sort_field: CREATED_AT,
          sort_direction: DESC, 
        },
        filter: {
          created_at: {
            from: "${date_create_from}",
            to:  "${date_create_to}",
          },
        },
        pageSize: 30,
        currentPage: 1
      ){
        page_info {
            page_size
            current_page
        }
        total_count
        items {
          order_number
          id
          created_at
          grand_total
          total {
            grand_total {
                value
                currency
            }
          }
          status
          table
          qty
          shipping_method
        }
      }
 
  }`,
    null
  );
};
export const apiGetListDining = () => {
  return callApi(
    ` {
  merchantOrderDashboard(
    sort: {
      created_at: ASC
    }
  ) {
    quotes {
        table
        table_id
        cart_id
        created_at
        total_quantity
        serving_status
        first_name
        phone_number
        prices {
            grand_total {
                value
                currency
            }
            cancel_item_total {
                value
                currency
            }
        }
    }
    orders {
        status
        table
        id
        order_number
        table_id
        created_at
        total_quantity
        serving_status
        order_source
        pickup_time
        pickup_date
        first_name
        phone_number
        total {
            grand_total {
                value
                currency
            }
            cancel_item_total {
                value
                currency
            }
        }
    }
  }
}
`,
    null
  );
};
export const apiGetListOrderRefund = () =>
  callApi(
    `{
    merchantOrderHistory(flagRefund: 1) {
      orders {
        address
        created_at
        delivery_time
        firstname
        grand_total
        id
        lastname
        order_number
        payment_method
        qty
        restaurant_id
        shipping_method
        flag_refund
        status
        use_plastic
        shipper_info {
          first_name
          last_name
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }`,
    null
  );

export const apiMoveOrderToAnother = (data) =>
  callApi(
    `mutation ($orderId:Int!, $restaurantId:Int!, $reason:String!) {
    moveOrder(orderId:$orderId, restaurantId:$restaurantId, reason:$reason) {
      result
    }
  }`,
    data
  );

export const apiReciveOrder = (data) =>
  callApi(
    `mutation($id: Int!){
      receivedOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiCookingOrder = (data) =>
  callApi(
    `mutation($id: Int!){
      cookingOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiCancelOrder = (data) =>
  callApi(
    `mutation($orderId: Int!){
    cancelOrder(id:$orderId){
      result
    }
  }`,
    data
  );

export const apiGetAllStoreInTheArea = () =>
  callApi(
    `{
      getStoreJsonData {
        url
        version
      }
    }`,
    null
  );

export const apiAcceptShipping = (data) =>
  callApi(
    `mutation (
    $id: Int!
    $type: Int!
  ){
    merchantAcceptShipping (
      id:  $id
      type: $type
    ) {
      result
    }
  }`,
    data
  );

export const apiMerchantReadyToShip = (data) =>
  callApi(
    `mutation($id: Int!){
      readyToShipOrder(id: $id){
      result
    }
  }`,
    data
  );

export const apiGetOrderStatusHistory = (data) =>
  callApi(
    `query($orderId: Int!){
    getOrderStatusHistory(
        orderId: $orderId
    ) {
        list {
            created_at
            status
            comment
        }
    }
}`,
    data
  );

export const apiGetDetailOrder = (data) =>
  callApi(
    `query($id: Int) {
      orderDetail(id:$id) {
        id
        order_number
        created_at
        updated_at
        grand_total
        status
        flag_refund
        payment_method
        payment_method_code
        firstname
        lastname
        phone
        waiter_name
        address
        customer_comment
        items {
            name
            qty
            price
            serving_status
            note
            options {
                name
                qty
                price
                note
            }
        }
        discount {
            amount {
                currency
                value
            }
            label
        }
        shipping_method
        use_plastic
        note
        customer_phone
        assign_from
        assign_reason
        shipping_amount
        table_id
        total{
        tip_amount{
            value
        }
        discounts{
            amount{
                value
            }
        }
        subtotal{
            value
        }
        total_shipping{
            value
        }
        total_tax{
            value
        }
        grand_total{
            value
        }
        cancel_item_total {
          value
          currency
        }    
            
    }
      }
  }`,
    data
  );
export const apiGetDetailQuote = (data) =>
  callApi(
    `query($cart_id: String!) {
  merchantCart(cart_id: $cart_id) {
    firstname
    lastname
    items {
      uid
      prices {
        total_item_discount {
          value
        }
        price {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      product {
        name
        sku
      }
      id
      quantity
      status
      created_at
      cooking_at
      ready_at
      done_at
      note
        ... on BundleCartItem {
          bundle_options {
            id
            label
            type
            values {
                id
                label
                price
                quantity
                status
                item_id
                created_at
                cooking_at
                ready_at
                done_at
                note
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            id
            option_label
            value_label
            value_id
            status
            item_id
            created_at
            cooking_at
            ready_at
            done_at
          }
        }
    }
    applied_coupons {
      code
    }
    tip_amount
    prices {
      discounts {
        amount {
          value
        }
        label
      }
      subtotal_excluding_tax{
        value
        currency
      }
      grand_total {
        value
        currency
      }
      applied_taxes{
        amount{
            value
        }
        label
      }
      cancel_item_total {
       value
       currency
      }
    }
    
  }
}
`,
    data
  );
export const apiBomOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      bomOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiCompleteOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      completeOrder(id:$id){
         result
       }
     }`,
    data
  );

export const apiShipping = (data) =>
  callApi(
    `mutation($id:Int!) {
      shippingOrder(id:$id){
        result
      }
    }`,
    data
  );

export const apiApproveRefund = (data) =>
  callApi(
    `mutation($id:Int!) {
      approveRefund(orderId:$id){
        result
      }
    }`,
    data
  );

export const apiGetOrderHistory = () =>
  callApi(
    `query {
  merchantOrders(
    sort: {
      sort_field: NUMBER, # or CREATED_AT
      sort_direction: DESC # or ASC
    },
   
    pageSize: 100,
    currentPage: 1
  ) {
    page_info {
        page_size
        current_page
    }
    total_count
    items {
      order_number
      id
      created_at
      grand_total
      total {
        grand_total {
            value
            currency
        }
      }
      status
      table
      pickup_date
      pickup_time
    }
  }
}`
  );

export const apiGetReasonCanel = () =>
  callApi(
    `query {
      storeConfig {
          reasons_cancel
      }
    }`
  );

export const apiGetTurnOffAppSound = () =>
  callApi(
    `query{
      storeConfig {
          turn_off_app_sound
      }
    }`
  );

export const apiGetTimeReloadOrder = () =>
  callApi(
    `query{
      storeConfig {
        time_reload_order
      }
    }`
  );

export const apiNotRefund = (data) =>
  callApi(
    ` mutation ($id: Int!, $shipperId: Int!) {
      noRefund(orderId: $id, shipperId: $shipperId) {
        result
      }
    }`,
    data
  );

export const apiPushSocketIdToServer = (data) =>
  callApi(
    `   mutation ($socketId: String!) {
      receiveSocketId(socketId: $socketId , account_type_tmp: "merchant") {
        result
      }
    }`,
    data
  );

export const getDetailBillOrder = (data) =>
  callApi(
    ` query ($id: Int!) {
        orderDetail(id: $id) {
            id
            restaurant_address
            restaurant_name
            restaurant_phone
            order_number
            created_at
            updated_at
            grand_total
            status
            flag_refund
            payment_method
            payment_method_code
            firstname
            lastname
            phone
            address
            customer_comment
            items {
                name
                qty
                price
                options {
                    name
                    qty
                    price
                }
            }
            discount {
                amount {
                    currency
                    value
                }
                label
            }
            shipping_method
            use_plastic
            note
            customer_phone
            assign_from
            assign_reason
            shipping_amount
            table
            order_source
            serve_name
            total {
                subtotal {
                    value
                    currency
                }
                discounts {
                    amount {
                        value
                        currency
                    }
                    label
                }
                total_tax {
                    value
                    currency
                }
                taxes {
                    amount {
                        value
                        currency
                    }
                    title
                    rate
                }
                total_shipping {
                    value
                    currency
                }
                grand_total {
                    value
                    currency
                }
            }
            feedback_url
            payment_methods {
                name
                type
                additional_data {
                    name
                    value
                }
            }
            tip_amount {
                value
                currency
            }
            __typename
        }
    }`,
    data
  );
