import { DatePicker, message, Select, Table } from "antd";
import { BatchMenuBar } from "components/BatchMenuBar";
import Header from "components/Header";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { columns } from "./column";
const { RangePicker } = DatePicker;
import SearchInput from "components/SearchInput";
import CustomButton from "components/CustomButton";
import { apiMerchantGetBatchSettles } from "apis/Settlements";
import moment from "moment";

export default function BatchHistory() {
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [searchDate, setSearchDate] = useState();

  const [batchSettles, setBatchSettles] = useState({
    data: [],
  });

  const windowHeight = window.innerHeight;

  const getMerchantGetBatchSettles = async ({
    currentPage = 1,
    pageSize = 10,
    search,
    createdAtFrom,
    createdAtTo,
  }) => {
    try {
      const params = {
        currentPage,
        pageSize,
        search,
      };
      if (createdAtFrom && createdAtTo) {
        params.createdAtFrom = createdAtFrom;
        params.createdAtTo = createdAtTo;
      }
      setLoading(true);
      const result = await apiMerchantGetBatchSettles(params);
      setLoading(false);
      if (result?.data?.merchantGetBatchSettles) {
        const data = result?.data?.merchantGetBatchSettles?.items;
        setBatchSettles({ data });
      }
    } catch (error) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setCurrentPage(pagination.current);
    getMerchantGetBatchSettles({
      search,
      page: pagination.current,
      field,
      position: order == "descend" ? "DESC" : "ASC",
    });
  };

  const handleDateChange = (value) => {
    setSearchDate(value);
  };

  const handleSearch = useCallback(() => {
    const params = {};
    const createdAtFrom = moment(searchDate?.[0]).format("YYYY-MM-DD");
    const createdAtTo = moment(searchDate?.[1]).format("YYYY-MM-DD");

    if (searchDate?.length >= 2) {
      params.createdAtFrom = createdAtFrom;
      params.createdAtTo = createdAtTo;
    }
    if (searchText) {
      params.search = searchText;
    }

    getMerchantGetBatchSettles(params);
  }, [searchText, searchDate, getMerchantGetBatchSettles]);

  useEffect(() => {
    getMerchantGetBatchSettles({ pageSize: 10, currentPage: 1 });
  }, []);

  return (
    <div>
      <Header isSearch={false} />
      <div className="container-box body_history">
        <BatchMenuBar title="Batch Settlements / Batch History" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 24,
            marginTop: 24,
            marginBottom: 24,
            alignItems: "center",
          }}
        >
          <RangePicker onChange={handleDateChange} size="large" />

          <SearchInput
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
            placeholder="Batch ID..."
            width="40%"
            minWidth="600px"
          />
          <CustomButton title="Search" onClick={handleSearch} />
        </div>
        <div style={{ flex: 1 }}>
          <Table
            loading={isLoading}
            rowKey="order_number"
            columns={columns}
            dataSource={batchSettles?.data}
            className="table-menu"
            rowClassName={"row-table-menu"}
            onRow={(record, index) => ({
              onClick: () => {
                // history.push(`edit_menu/${record?.entity_id}`);
                console.log(record);
              },
            })}
            scroll={{ y: windowHeight - 300 }}
            pagination={{
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

const MockData = [
  {
    id: "87341",
    created_at: "09/12/2024 12:31 AM",
    invoice_number: "22632323",
    status: "Paid",
    payment: {
      method: "Visa",
      last4: "1234",
    },
    type: "CREDIT",
    total: "10.00",
  },
];
