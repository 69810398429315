import { useState } from "react";
import { Modal, Spin, notification } from "antd";
import { Button } from "antd";
import RadioBtnSelected from "./icons/radioBtnSelected";
import { apiPrintInvoice } from "apis/Printer";

export const ModalSelectBillToPrint = ({
  data,
  onPressOK,
  isVisibleModal,
  setVisibleMoal,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [isPrinting, setIsPrinting] = useState(false);

  const handleCancel = () => {
    setVisibleMoal(false);
  };

  const handleChange = (item) => {
    setSelectedOption(item);
  };
  const handleOk = async () => {
    setIsPrinting(true);
    try {
      await apiPrintInvoice({ invoice_number: selectedOption?.number });
      notification.success({
        message: "Send request print bill success",
        description: "Please go to printer to take the bill!",
      });
    } catch (err) {
      notification.error({
        message: "Error",
        description: err?.errors?.[0]?.message,
      });
    } finally {
      setIsPrinting(false);
      setVisibleMoal(false);
    }
  };
  return (
    <>
      <Modal
        open={isVisibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: 16 }}
        styles={{
          content: {
            backgroundColor: "var(--neutral-primary)",
            boxShadow: "none",
          }, // turns the Modal #191919,
          header: {
            background: "var(--neutral-primary)",
            color: "white",
          },
        }}
        closeIcon={null}
        footer={null}
      >
        <div style={{ paddingTop: 8 }}>
          <p
            style={{
              color: "var(--text-primary)",
              fontSize: 24,
              fontWeight: "600",
              marginBottom: 24,
            }}
          >
            Receipts
          </p>
          {data?.length > 0 ? (
            data?.map?.((pos) => (
              <Button
                key={`pos ${pos?.id}`}
                style={{
                  height: 56,
                  width: "100%",
                  background: "var(--neutral-base)",

                  borderRadius: 8,
                  border: `1px solid  var(--neutral-line)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: 12,
                }}
                onClick={() => handleChange(pos)}
              >
                <div
                  style={{
                    width: 30,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {pos?.id == selectedOption?.id && <RadioBtnSelected />}
                </div>
                <p>{pos?.number}</p>
              </Button>
            ))
          ) : (
            <Spin />
          )}
          <Button
            style={{
              height: 56,
              width: "100%",
              background: selectedOption
                ? "var(--primary-6)"
                : "var(--bg-disabled)",
              color: "var(--primary-1)",
              fontSize: 16,
              fontWeight: 600,
              cursor: "pointer",
              marginTop: 20,
              borderRadius: 8,
            }}
            disabled={!selectedOption}
            loading={isPrinting}
            title="Select"
            onClick={handleOk}
          >
            Select
          </Button>
        </div>
      </Modal>
    </>
  );
};
