export function getLastFourChars(str) {
  if (str?.length <= 4) {
    return str; // Trả về chuỗi gốc nếu chuỗi có độ dài nhỏ hơn hoặc bằng 4
  }
  return str?.slice(-4);
}

export function capitalizeFirstLetter(str) {
  return (
    str
      ?.split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ") || "New"
  );
}
