import { callApi } from "apis";

export const apiGetTransactions = (variables) =>
  callApi(
    `query($currentPage: Int, $pageSize: Int, $startDate: String, $endDate: String, $status: String,$search: String) {
  merchantGetTransactions(
    search: $search,
    filter: {
        date_from: $startDate,
        date_to: $endDate,
        status: $status #UNPAID, PAID, REFUNDED
    },
    currentPage: $currentPage,
    pageSize: $pageSize
) {
    total_items
    items {
      batch_id
      date_time
      invoice_number
      state
      payment_methods {
        name
        type
        additional_data {
            name
            value
        }
      }
      type
      total {
        value
        currency
      }
    }
    page_info {
      page_size
      current_page
      total_pages
    }
  }
}
`,
    variables
  );
