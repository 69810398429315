import { callApi } from "apis";

export const apiGetCompletedOrder = (data) =>
  callApi(
    `query ($currentPage: Int,$filter: OrderDashboardFilterInput) {
    merchantOrderCompleteDashboard (
      filter: $filter,
      currentPage: $currentPage
      pageSize: 20
      sort: {
        created_at: DESC
      }
    ) {
      orders {
         status
          table
          table_id
          id
          order_number
          created_at
          total_quantity
          serving_status
          order_source
          pickup_time
          pickup_date
          first_name
          phone_number
          total {
              grand_total {
                  value
                  currency
              }
                  
          }
      }
      total_count
      page_info {
          page_size
          current_page
          total_pages
      }
    }
  }`,
    data
  );
