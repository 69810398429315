import { callApi } from "..";

export const apiMerchantCreateProduct = (variables) =>
  callApi(
    `mutation(
        $name: String!, 
        $description: String, 
        $open_price: Boolean!,
        $price: Float!,
        $sku: String!,
        $status: Int,
        $is_in_stock: Boolean,
        $category_id: Int,
        $display_platforms: [String],
        $media_gallery_entries: [ProductMediaGalleryEntry],
        $quantity: Int!
    ) {
        merchantCreateProduct(
            input: {
              open_price: $open_price,
              name: $name,
              description: $description,
              price: $price,
              sku: $sku,
              status: $status,
              weight: 1,
              display_platforms: $display_platforms,
              custom_attributes: [
                {
                    attribute_code: "category_ids",
                    value: "[]"
                }
              ],
              media_gallery_entries: $media_gallery_entries,
              extension_attributes: {
                category_links: [
                  {
                      position: 3,
                      category_id: $category_id
                  }
                ],
                stock_item: {
                      is_in_stock: $is_in_stock
                      qty: $quantity
                  }
              }
            }
          ) {
              id
              sku
              name
              status
              open_price
              display_platforms
              description_plain_text
              categories {
                id
                name
              }
              image {
                url
                label
                position
                disabled
              }
              small_image {
                url
                label
                position
                disabled
              }
              thumbnail {
                url
                label
                position
                disabled
              }
              media_gallery_entries {
                id
                uid
                media_type
                label
                position
                disabled
                types
                file
              }
              media_gallery {
                url
                label
                position
                disabled
              }
              special_price
              price {
                minimalPrice {
                  amount {
                    value
                    currency
                  }
                }
                maximalPrice {
                  amount {
                    value
                    currency
                  }
                }
                regularPrice {
                  amount {
                    value
                    currency
                  }
                }
              }
              stock_status
              only_x_left_in_stock
          }
    }`,
    variables
  );

export const apiMerchantUpdateProduct = (variables) =>
  callApi(
    `mutation(
        $name: String!, 
        $description: String, 
        $open_price: Boolean!,
        $price: Float!,
        $sku: String!,
        $status: Int
        $is_in_stock: Boolean
        $category_id: Int
        $display_platforms: [String]
        $media_gallery_entries: [ProductMediaGalleryEntry]
         $quantity: Int!
        ) {
          merchantUpdateProduct(
            input: {
              open_price: $open_price
              name: $name
              description: $description
              price: $price
              sku: $sku
              status: $status
              weight: 1
              display_platforms: $display_platforms
              custom_attributes: [
                {
                    attribute_code: "category_ids",
                    value: "[]"
                }
              ]
              media_gallery_entries: $media_gallery_entries
              extension_attributes: {
                category_links: [
                  {
                      position: 3
                      category_id: $category_id
                  }
                ]
                stock_item: {
                      is_in_stock: $is_in_stock
                      qty: $quantity
                  }
              }
            }
          ) {
              id
              sku
              name
              status
              open_price
              display_platforms
              description_plain_text
              categories {
                id
                name
              }
              image {
                url
                label
                position
                disabled
              }
              small_image {
                url
                label
                position
                disabled
              }
              thumbnail {
                url
                label
                position
                disabled
              }
              media_gallery_entries {
                id
                uid
                media_type
                label
                position
                disabled
                types
                file
              }
              media_gallery {
                url
                label
                position
                disabled
              }
              special_price
              price {
                minimalPrice {
                  amount {
                    value
                    currency
                  }
                }
                maximalPrice {
                  amount {
                    value
                    currency
                  }
                }
                regularPrice {
                  amount {
                    value
                    currency
                  }
                }
              }
              stock_status
              only_x_left_in_stock
          }
  }`,
    variables
  );

export const apiMerchantDeleteProduct = (variables) =>
  callApi(
    `mutation($id: Int!) {
      merchantDeleteProduct(id: $id)
      }
    `,
    variables
  );

export const apiGetProductDetail = (variables) =>
  callApi(
    `query($id: Int!) {
        merchantProduct(
          id: $id
        ) {
          id
          sku
          name
          status
          
          qty
          open_price
          display_platforms
          description_plain_text
          categories {
            id
            name
          }
          image {
            url
            label
            position
            disabled
          }
          small_image {
            url
            label
            position
            disabled
          }
          thumbnail {
            url
            label
            position
            disabled
          }
          media_gallery_entries {
            id
            uid
            media_type
            label
            position
            disabled
            types
            file
          }
          media_gallery {
            url
            label
            position
            disabled
          }
          special_price
          price {
            minimalPrice {
              amount {
                value
                currency
              }
            }
            maximalPrice {
              amount {
                value
                currency
              }
            }
            regularPrice {
              amount {
                value
                currency
              }
            }
          }
          stock_status
          only_x_left_in_stock
        }
      }
      `,
    variables
  );

export const apiGetKitchenStation = (data) =>
  callApi(
    `query {
        getKitchenStations {
          id
          restaurant_id
          name
          printer_id
        }
      }
    `,
    data
  );

export const apiGetProduct = (variables) => {
  const {
    field = "id",
    position = "DESS",
    search = "",
    page = 1,
    pageSize = 10,
  } = variables;
  return callApi(
    `query($search: String, $currentPage: Int, $pageSize: Int) {
        merchantProducts (
          search: $search
          currentPage: $currentPage
          pageSize: $pageSize
          sort: { ${field}: ${position} }
          filter: {} 
        ) 
          {
          items {
            id
            sku
            open_price
            display_platforms
            name
            status
            qty
            description_plain_text
            categories {
              id
              name
            }
            image {
              url
              label
              position
              disabled
            }
            small_image {
              url
              label
              position
              disabled
            }
            thumbnail {
              url
              label
              position
              disabled
            }
            media_gallery_entries {
              id
              uid
              media_type
              label
              position
              disabled
              types
              file
            }
            media_gallery {
              url
              label
              position
              disabled
            }
            special_price
            price {
              minimalPrice {
                amount {
                  value
                  currency
                }
              }
              maximalPrice {
                amount {
                  value
                  currency
                }
              }
              regularPrice {
                amount {
                  value
                  currency
                }
              }
            }
            stock_status
            only_x_left_in_stock
          }
          total_count
          page_info {
            total_pages
            current_page
            page_size
          }
        }
      }
      `,
    {
      search,
      page,
      pageSize,
    }
  );
};
