import { Spin, Modal } from "antd";
import { Button } from "../../components/atom/Button";
import { TextDark } from "../../components/atom/Text/index";
import React, { useEffect, useState } from "react";
import { getDetailBillOrder } from "apis/Order";
import { useHistory, useLocation } from "react-router-dom";
import { apiGetInvoice } from "apis/Invoice";
import { ButtonContainer, ButtonLeftContainer, Container } from "./styled";
import { ButtonBill } from "./components/ButtonBill";
import { useMediaQuery } from "react-responsive";
import { ButtonSelectBill } from "./components/ButtonSelectBill";
import { RenderBill } from "./components/RenderBill";
import ModalInput from "./components/ModalInput";
import { apiPrintBill, apiSendBillToPhone, apiSendToEmail } from "apis/Printer";
// import { usePrinter } from "context/printerContext";
const CURRENTCY = "$";
function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

export default function index() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [dataSplitBill, setDataSplitBill] = useState();
  const onGetData = (data) => {
    setLoading(true);
    getDetailBillOrder(data)
      .then((res) => {
        setData(res.data);
        apiGetInvoice({
          OrderNumber: res.data?.orderDetail?.order_number,
        }).then((res) => {
          setDataSplitBill(res.data);
        });
      })
      .finally(() => setLoading(false));
  };
  const searchParams = useSearchParams();
  const orderId = searchParams.get("orderId");
  const order_ID = searchParams.get("order_id");
  const navigation = useHistory();
  useEffect(() => {
    if (orderId !== null && orderId !== "undefined") {
      onGetData({ id: orderId });
    }
  }, [orderId]);
  useEffect(() => {
    if (order_ID !== null && order_ID !== "undefined") {
      onGetData({ id: order_ID });
    }
  }, [order_ID]);
  const [childBill, setChildBill] = useState([]);
  const [selectDataShowbill, setSelectDataShowbill] = useState();
  useEffect(() => {
    if (dataSplitBill?.merchantGetOrderInvoices) {
      const dataBill = dataSplitBill?.merchantGetOrderInvoices;
      if (dataBill?.invoice?.length > 1) {
        setChildBill(dataBill?.invoice);
      }
    }
  }, [dataSplitBill]);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const ModalError = (title) => {
    Modal.error({
      title: "Error",
      content: title ? title : "Print bill failed, Please try again!",
      centered: true,
    });
  };
  const PrintBillApi = () => {
    setModalLoading(true);
    if (childBill.length) {
      apiPrintBill({
        invoice_number: selectDataShowbill.number,
      })
        .then(() => {
          Modal.success({
            title: "Send request print bill success",
            content: "Please go to printer to take the bill!",
            centered: true,
          });
        })
        .catch((e) => {
          ModalError();
        })
        .finally(() => {
          setModalLoading(false);
        });
    } else {
      if (dataSplitBill?.merchantGetOrderInvoices?.invoice.length === 0) {
        apiGetInvoice({
          OrderNumber: data?.orderDetail?.order_number,
        }).then((res) => {
          const newData = res?.data?.merchantGetOrderInvoices;
          apiPrintBill({
            invoice_number: newData.invoice[0]?.number,
          })
            .then(() => {
              Modal.success({
                title: "Send request print bill success",
                content: "Please go to printer to take the bill!",
                centered: true,
              });
            })
            .catch((e) => {
              ModalError("Please payment to take the bill!");
            })
            .finally(() => {
              setModalLoading(false);
            });
        });
        return;
      }
      apiPrintBill({
        invoice_number:
          dataSplitBill?.merchantGetOrderInvoices?.invoice[0]?.number,
      })
        .then(() => {
          Modal.success({
            title: "Send request print bill success",
            content: "Please go to printer to take the bill!",
            centered: true,
          });
        })
        .catch((e) => {
          ModalError();
        })
        .finally(() => {
          setModalLoading(false);
        });
    }
  };
  const [modalLoading, setModalLoading] = useState(false);
  const handleSendBill = (type, value, invoiceNumber) => {
    if (!invoiceNumber) {
      return;
    }
    setModalLoading(true);
    if (type === "email") {
      apiSendToEmail({
        invoiceNumber: invoiceNumber,
        email: value,
      })
        .then(() => {
          Modal.success({
            title: "Success",
            content: `Send to ${value} success.`,
            centered: true,
          });
        })
        .catch((err) => {
          console.log(err);
          Modal.error({
            title: "Error",
            content: `Send to ${value} failed. Please try again.`,
            centered: true,
          });
        })
        .finally(() => {
          setModalLoading(false);
        });
    } else {
      apiSendBillToPhone({
        invoiceNumber: `${invoiceNumber}`,
        phoneNumber: value,
        region_code: "+1",
      })
        .then(() => {
          Modal.success({
            title: "Success",
            content: `Send to ${value} success.`,
            centered: true,
          });
        })
        .catch((err) => {
          Modal.error({
            title: "Error",
            content: `Send to ${value} failed. Please try again.`,
            centered: true,
          });
        })
        .finally(() => {
          setModalLoading(false);
        });
    }
  };
  const [modalInputEmail, setModalInputEmail] = useState(false);
  return (
    <Container>
      {modalLoading && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            zIndex: 10000,
            background: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <Spin size="large" tip="Loading..." />
        </div>
      )}
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: -1,
          background: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Spin size="large" tip="Loading..." />}
      </div>
      <ModalInput
        isModalOpen={modalInputEmail}
        title="Input customer e-mail"
        onSubmit={(value) => {
          handleSendBill(
            "email",
            value,
            selectDataShowbill
              ? selectDataShowbill?.number
              : dataSplitBill?.merchantGetOrderInvoices?.invoice[0]?.number
          );
          setModalInputEmail(false);
        }}
        onCancel={() => {
          setModalInputEmail(false);
        }}
        type="email"
      />
      {!loading && (
        <>
          <RenderBill
            data={data?.orderDetail}
            selectDataShowbill={selectDataShowbill}
          />

          <ButtonContainer>
            {(childBill.length === 0 || selectDataShowbill) && (
              <>
                <ButtonBill title="Print" onPress={PrintBillApi} />
                <ButtonBill
                  title="Email"
                  onPress={() => setModalInputEmail(true)}
                />
                {/* <ButtonBill
                                        title="Sms"
                                        onPress={() => setModalInputPhone(true)}
                                    /> */}
              </>
            )}
            <Button
              style={{
                height: 56,
                width: isMobile ? "auto" : 160,
                display: "flex",
                border: `2px solid var(--primary-6)`,
                padding: "0 16px",
                background: "var(--neutral-base)",
              }}
              onClick={() => navigation.push("/")}
            >
              <TextDark
                style={{
                  color: "var(--primary-6)",
                  fontWeight: "600",
                }}
              >
                Close
              </TextDark>
            </Button>
          </ButtonContainer>

          {childBill.length > 1 && (
            <ButtonLeftContainer>
              <ButtonSelectBill
                onPress={() => setSelectDataShowbill(undefined)}
                isSelected={!selectDataShowbill}
              />
              {childBill.map((item, index) => (
                <ButtonSelectBill
                  key={index}
                  title={`${index + 1}`}
                  onPress={() => {
                    setSelectDataShowbill(item);
                  }}
                  isSelected={selectDataShowbill === item}
                />
              ))}
            </ButtonLeftContainer>
          )}
        </>
      )}
    </Container>
  );
}
