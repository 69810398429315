import React, { useContext, useState } from "react";
import { Drawer } from "antd";
import StoreIcon from "layouts/icons/storeIcon";
import { RenderItem, RenderLogout, Header } from "./components/components";
import BoardMenuIcon from "./icons/boardMenu";
import { useHistory } from "react-router-dom";
import { ConfirmLogoutModal } from "components/Modal/ModalConfirmLogout";
import MenuManagerIcon from "./icons/menuManagerIcon";
import CustomerIcon from "./icons/customerIcon";
import ModalSetUpPrinter from "./components/ModalSetUpPrinter";
import {
  HistoryOutlined,
  PrinterOutlined,
  SettingFilled,
} from "@ant-design/icons";
import BillIcon from "./icons/receiptV2Bill";
import KitchenIcon from "./icons/kitchenIcon";
const BASE_ROUTER = {
  Menu: "/menu",
  HOME: "/",
  Settle: "/settle",
};
const urlWaiter = process.env.REACT_APP_WAITER_URL;
const urlKitchen = process.env.REACT_APP_KITCHEN_URL;
export default function DrawerMenu() {
  const [open, setOpen] = useState(false);
  // const { isLightMode, toggleMode } = useContext(ThemeContext);
  const isLightMode = false;
  const navigation = useHistory();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const MenuData = [
    {
      title: "Go Kitchen",
      icon: <KitchenIcon />,
      to: `${urlKitchen}home?token=${localStorage.getItem("access_token")}`,
      isGo: true,
    },
    {
      title: "Menu Manager",
      icon: <MenuManagerIcon />,
      to: BASE_ROUTER.Menu,
    },
    {
      title: "Order History",
      icon: <HistoryOutlined style={{ fontSize: 34 }} />,
      to: `${urlWaiter}bills?token=${localStorage.getItem(
        "access_token"
      )}&from=merchant`,
      isGo: true,
    },
    {
      title: "Batch Settlements",
      icon: <BillIcon />,
      to: BASE_ROUTER.Settle,
    },
    {
      title: "Customer Infomation",
      icon: <CustomerIcon />,
      to: `${urlWaiter}customer/list?token=${localStorage.getItem(
        "access_token"
      )}&from=merchant`,
      isGo: true,
    },
    {
      title: "Printer Setting",
      icon: <PrinterOutlined style={{ fontSize: 30 }} />,
      isAction: true,
      action: () => setOpenPrinterModal(true),
    },

    {
      title: "Settings",
      icon: <SettingFilled style={{ fontSize: 36 }} />,
      to: `${urlWaiter}restaurent/terminal?token=${localStorage.getItem(
        "access_token"
      )}&from=merchant`,
      isGo: true,
    },
  ];
  const [isShowConfirmLogout, setIsShowConfirmLogout] = useState(false);
  const onLogout = async () => {
    setIsShowConfirmLogout(true);
  };
  const [openPrinterModal, setOpenPrinterModal] = useState(false);
  return (
    <>
      <ConfirmLogoutModal
        isShowConfirmLogout={isShowConfirmLogout}
        closeModalConfirmLogout={() => setIsShowConfirmLogout(false)}
      />
      <ModalSetUpPrinter
        isVisibleModal={openPrinterModal}
        setVisibleMoal={setOpenPrinterModal}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: 20,
          paddingBottom: 2,
        }}
        onClick={showDrawer}
      >
        <BoardMenuIcon />
      </div>
      <Drawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        bodyStyle={{
          background: "var(--form-background)",
          padding: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        headerStyle={{ display: "none" }}
      >
        <div style={{ flex: 1, overflow: "auto" }}>
          <Header
            onClose={onClose}
            onLogo={() => {
              navigation.push(BASE_ROUTER.HOME);
            }}
          />
          {MenuData.map((item, index) => (
            <RenderItem
              key={index}
              icon={item.icon}
              title={item.title}
              onPress={() => {
                if (item?.isGo) {
                  window.location.href = item.to;
                  return;
                }
                if (item.isAction) {
                  item.action();
                  return;
                }
                navigation.push(item.to);
              }}
              color={"var(--text-primary)"}
            />
          ))}
        </div>
        <RenderLogout onPress={onLogout} />
      </Drawer>
    </>
  );
}
