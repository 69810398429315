import React from "react";

export default function MenuManagerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="36"
      viewBox="0 0 30 36"
      fill="none"
    >
      <path
        d="M28.3334 28V14.6667C28.3334 11.5233 28.3334 9.95167 27.3567 8.97667C26.3801 8 24.8101 8 21.6667 8H1.66675V28C1.66675 31.1417 1.66675 32.7133 2.64341 33.69C3.62008 34.6667 5.19008 34.6667 8.33341 34.6667H21.6667C24.8101 34.6667 26.3801 34.6667 27.3567 33.69C28.3334 32.7133 28.3334 31.1417 28.3334 28Z"
        stroke={"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0001 16.3337C16.3262 16.3337 17.5979 16.8605 18.5356 17.7982C19.4733 18.7358 20.0001 20.0076 20.0001 21.3337M15.0001 16.3337C13.674 16.3337 12.4022 16.8605 11.4645 17.7982C10.5269 18.7358 10.0001 20.0076 10.0001 21.3337M15.0001 16.3337V14.667M20.0001 21.3337H10.0001M20.0001 21.3337H21.6667M10.0001 21.3337H8.33341M8.33341 28.0004H21.6667M1.66675 8.00037L13.9751 2.8437C16.7234 1.69203 18.0967 1.11537 19.1917 1.41037C19.9017 1.60115 20.5287 2.02128 20.9751 2.60537C21.6667 3.5087 21.6667 5.00537 21.6667 8.00037"
        stroke={"#121212"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
